var page_loaded = 1;
global.page_loaded = page_loaded;

global.jQuery = require('jquery');
var slickInit = require('slick-carousel');

page = {
	ready: function(){
		this.tabAddClass();
	},
	load: function(){
		this.catProdGal();
		this.tabsInit();
		this.disegniInit();
	},
	catProdGal: function(){	
		jQuery('.prodIntro__imagesWrap').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			speed: 700,
			asNavFor: '.prodIntro__thumbsWrap',
			arrows: false,
			dots: false,
			touch: false,
			touchMove: false,
			draggable: false,
			swipeToSlide: false,
			touchThreshold: 50000
		});
		jQuery('.prodIntro__thumbsWrap').slick({
			vertical: true,
			slidesToShow: 5,
			slidesToScroll: 1,
			asNavFor: '.prodIntro__imagesWrap',
			focusOnSelect: true,
			speed: 700,
			arrows: false,
		  	dots: false,
		  	touch: false,
			touchMove: false,
			draggable: false,
			swipeToSlide: false,
			touchThreshold: 50000,
			responsive: [
			    {
			    	breakpoint: 991,
					settings: {
						vertical: false,
					}
			    }
			]
		});

		jQuery('.prodIntro__imagesWrap, .prodIntro__thumbsWrap').on('beforeChange', function () {
			siteGlobal.lazyLoad();
		});
	},
	tabAddClass: function(){
		$('.tab:first-child, .modList__tab:first-child').addClass('active');
	},
	tabsInit: function(){
		$('.tab').on('click', function(){
			var tab = $(this).data('tab-sel');
			$('.tab, .modList__tab').removeClass('active');
			$(this).addClass('active');
			$('[data-tab="'+ tab +'"]').addClass('active');
		})
	},
	disegniInit: function(){
		if (window.outerWidth < 768) {
			jQuery('.prodIntro__disegni').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				speed: 700,
				arrows: true,
				autoplay: true,
				autoplaySpeed: 2500,
				prevArrow: $('.az-prev-custom'),
			  	nextArrow: $('.az-next-custom')
			});
		}
	}

}

$(document).ready(function(){
	page.ready();
})

$(window).on('load', function(){
	page.load();
})

$(window).resize(function(e){
	if(window.innerWidth < 768) {
        if(!jQuery('.prodIntro__disegni').hasClass('slick-initialized')){
            page_home.settoriGal();
        }
    } else {
        if(jQuery('.prodIntro__disegni').hasClass('slick-initialized')){
            jQuery('.prodIntro__disegni').slick('unslick');
        }
    }
});